import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { NO_RESET_WHITE_LIST } from '@/constants'

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'Root',
    meta: {
      hidden: true
    },
	  children: [
		  {
			  path: 'home',
			  component: () => import('@/views/home/index.vue'),
			  name: 'home',
			  meta: {
				  hidden: false,
				  title: '首页',
			  }
		  },
		  {
			path: 'adminmenu',
			component: () => import('@/views/adminmenu/index.vue'),
			name: 'adminmenu',
			meta: {
			  hidden: false,
			  title: '系统权限',
			}
		  }
	  ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: '登录',
      noTagsView: true
    }
  },{
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  },
	{
		path: '/redirect',
		component: Layout,
		name: 'Redirect',
		children: [
			{
				path: '/redirect/:path(.*)',
				name: 'Redirect',
				component: () => import('@/views/Redirect/Redirect.vue'),
				meta: {}
			}
		],
		meta: {
			hidden: true,
			noTagsView: true
		}
	}
]

export const asyncRouterMap: AppRouteRecordRaw[] = []

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
